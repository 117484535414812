<template>
	<div :ref="'wrapper_'+this.id" :class="this.wrapper">
		<label v-if="this.label" :for="this.id" class="form-label" :class="this.labelClass">
			{{ this.label }}<sup v-if="this.required" class="text-danger">*</sup>
		</label>
		<div class="d-inline-flex align-items-stretch input-group" :class="this.group">
			<div v-if="this.prepend" v-html="this.prepend" class="input-group-text text-dark-gray border-0 bg-transparent p-0 pe-3" />
			<DatePick v-model:value="this.datetime"
					  :pickTime="Boolean(this.$props.pickTime)"
					  :pickMinutes="Boolean(this.$props.pickMinutes)"
					  :pickSeconds="Boolean(this.$props.pickSeconds)"
					  :isDateDisabled="this.$props.isDateDisabled"
					  :format="this.$props.inputFormat"
					  :use12HourClock="Boolean(this.$props.use12HourClock)"
					  :displayFormat="this.$props.displayFormat"
					  :hasInputElement="Boolean(this.$props.hasInputElement)"
					  :editable="Boolean(this.$props.inputEditable)"
					  :nextMonthCaption="this.$props.nextMonthCaption"
					  :prevMonthCaption="this.$props.prevMonthCaption"
					  :setTimeCaption="this.$props.setTimeCaption"
					  :weekdays="this.$props.weekdays"
					  :months="this.$props.months"
					  :inputAttributes="this.attributes"
					  :class="{
						  'is-valid' : (this.valid),
						  'is-invalid' : (this.invalid && typeof this.invalid != 'boolean'),
					  }"
					  @periodChange="(value, event) => this.periodChange(value, event)"
					  @change="(value, event) => this.setValue(value, event)"
					  @input="(value, event) => this.setValue(value, event)" />
			<div v-if="this.append" v-html="this.append" class="input-group-text text-dark-gray border-0 bg-transparent bg-0 p-0 ps-3" />
			<div v-if="this.remove" class="input-group-text align-self-start text-dark-gray border-0 bg-transparent bg-0 p-0 ps-3">
				<button type="button"
						aria-label="Удалить"
						class="d-flex align-items-center justify-content-center btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-1"
						style="width: 44px; height: 44px;"
						@click="this.emitRemove">
					<img :src="require('@/assets/icons/delete.svg')" class="d-block" alt="Удалить" width="14" height="14">
				</button>
			</div>
			<div v-if="this.valid && typeof this.valid != 'boolean'" class="valid-feedback" v-html="this.valid" />
			<div v-else-if="this.invalid && typeof this.invalid != 'boolean'" class="invalid-feedback" v-html="this.invalid" />
		</div>
		<div v-if="this.help" class="form-text" :class="this.helpClass" v-html="this.help" />
	</div>
</template>

<script>

	// https://dbrekalo.github.io/vue-date-pick/
	import DatePick from "vue-date-pick/src/vueDatePick";
	import moment from "moment";
	import CommonService from "@/services/CommonService";

	export default {
		name: "DateTimePicker",
		components: {
			DatePick: CommonService.mergeRecursive(DatePick, {
				methods: {
					inspectCloseEvent(event) {
						if (event.keyCode) {
							event.keyCode === 27 && this.close();
						} else if (
							!(event.target === this.$el) &&
							(
								!this.$el.contains(event.target) &&
								!this.$el.classList.contains('modal') &&
								!this.$el.classList.contains('modal-backdrop')
							)
						) {
							this.close();
						}
					},
					addCloseEvents() {
						if (!this.closeEventListener) {
							this.closeEventListener = e => this.inspectCloseEvent(e);
							['click', 'keyup', /*'focusin'*/].forEach(
								eventName => document.addEventListener(eventName, this.closeEventListener)
							);
						}
					},
				}
			}),
		},
		props: {
			inputId: {type: String},
			inputLabel: {type: String},
			inputLabelClass: {type: String},
			inputType: {type: String},
			inputName: {type: String},
			inputValue: {type: String},
			inputClass: {type: String},
			inputGroupClass: {type: String},
			inputWrapClass: {type: String},
			inputHelpText: {type: String},
			inputHelpClass: {type: String},
			inputPlaceholder: {type: String},
			inputDisabled: {type: [String, Boolean], default: false},
			inputRequired: {type: [String, Boolean], default: false},
			inputReadonly: {type: [String, Boolean], default: false},
			inputPrepend: {type: String},
			inputAppend: {type: String},
			inputRemove: {type: [String, Boolean], default: false},
			inputValid: {type: [String, Boolean], default: false},
			inputInValid: {type: [String, Boolean], default: false},


			inputFormat: {type: String, default: 'YYYY-MM-DD HH:mm'},
			displayFormat: {type: String, default: 'YYYY.MM.DD H:mm A'},
			pickTime: {type: [String, Boolean], default: false},
			inputEditable: {type: [String, Boolean], default: true},
			dateFromNow: {type: [String, Boolean], default: false},
			pickMinutes: {type: [String, Boolean], default: true},
			pickSeconds: {type: [String, Boolean], default: false},
			isDateDisabled: {type: Function, default: () => false},
			hasInputElement: {type: [String, Boolean], default: true},
			use12HourClock: {type: [String, Boolean], default: false},
			nextMonthCaption: {type: String, default: 'След. месяц'},
			prevMonthCaption: {type: String, default: 'Пред. месяц'},
			setTimeCaption: {type: String, default: 'Укажите время:'},
			weekdays: {
				type: Array,
				default: () => ([
					'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'
				])
			},
			months: {
				type: Array,
				default: () => ([
					'Январь', 'Февраль', 'Март', 'Апрель',
					'Май', 'Июнь', 'Июль', 'Август',
					'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
				])
			}
		},
		data() {
			return {
				id: (typeof this.$props.inputId !== "undefined") ? this.$props.inputId : '',
				label: (typeof this.$props.inputLabel !== "undefined") ? this.$props.inputLabel : '',
				labelClass: (typeof this.$props.inputLabelClass !== "undefined") ? this.$props.inputLabelClass : 'text-dark-gray',
				name: (typeof this.$props.inputName !== "undefined") ? this.$props.inputName : '',
				class: (typeof this.$props.inputClass !== "undefined") ? 'form-control ' + this.$props.inputClass : 'form-control',
				group: (typeof this.$props.inputGroupClass !== "undefined") ? this.$props.inputGroupClass : '',
				wrapper: (typeof this.$props.inputWrapClass !== "undefined") ? this.$props.inputWrapClass : '',
				helpClass: (typeof this.$props.inputHelpClass !== "undefined") ? this.$props.inputHelpClass : '',
				placeholder: (typeof this.$props.inputPlaceholder !== "undefined") ? this.$props.inputPlaceholder : '',
				from_now: (typeof this.$props.dateFromNow !== "undefined") ? this.$props.dateFromNow : false,
				remove: (typeof this.$props.inputRemove !== "undefined") ? this.$props.inputRemove : false,
				valid: (typeof this.inputValid !== "undefined") ? this.inputValid : false,
				invalid: (typeof this.inputInValid !== "undefined") ? this.inputInValid : false,
			}
		},
		methods: {
			parseDate(dateString, format) {
				return moment.parse(dateString, format);
			},
			formatDate(dateObj, format) {
				return moment.format(dateObj, format);
			},
			periodChange(value, event) {
				this.$emit('periodChange', value, event);
			},
			setValue(value, event) {
				this.$emit('setValue', value, event);
			},
			emitRemove() {
				if (this.$emit('removeValue', this.value)) {
					this.$refs['wrapper_'+this.id].remove();
				}
			}
		},
		mounted() {

			/*let vdpArrowPrev = document.querySelector('.vdpArrowPrev');
			if (vdpArrowPrev) {
				vdpArrowPrev.stopPropagation();
				vdpArrowPrev.addEventListener('click', () => alert('vdpArrowPrev!'));
			}

			let vdpArrowNext = document.querySelector('.vdpArrowNext');
			if (vdpArrowNext) {
				vdpArrowNext.stopPropagation();
				vdpArrowNext.addEventListener('click', () => alert('vdpArrowNext!'));
			}*/

			/*this.$nextTick(() => {
				let vdpArrowPrev = document.querySelector('.vdpArrowPrev');
				if (vdpArrowPrev) {
					vdpArrowPrev.stopPropagation();
					vdpArrowPrev.addEventListener('click', () => alert('vdpArrowPrev!'));
					CommonService.delegate('click', '.vdpArrowPrev', alert());
				}

				let vdpArrowNext = document.querySelector('.vdpArrowNext');
				if (vdpArrowNext) {
					vdpArrowNext.stopPropagation();
					vdpArrowNext.addEventListener('click', () => alert('vdpArrowNext!'));
				}
			});*/

			CommonService.delegate('click', '.vdpArrowPrev', (event) => {
				console.info(event);
			});
			CommonService.delegate('click', '.vdpArrowNext', (event) => {
				console.info(event);
			});

		},
		watch: {

		},
		computed: {
			disabled() {
				return (typeof this.$props.inputDisabled !== "undefined") ? this.$props.inputDisabled : false;
			},
			required() {
				return (typeof this.$props.inputRequired !== "undefined") ? this.$props.inputRequired : false;
			},
			readonly() {
				return (typeof this.$props.inputReadonly !== "undefined") ? this.$props.inputReadonly : false;
			},
			help() {
				return (typeof this.$props.inputHelpText !== "undefined") ? this.$props.inputHelpText : '';
			},
			prepend() {
				return (typeof this.$props.inputPrepend !== "undefined") ? this.$props.inputPrepend : false;
			},
			append() {
				return (typeof this.$props.inputAppend !== "undefined") ? this.$props.inputAppend : false;
			},
			attributes() {
				let attributes = {}

				if (this.id)
					attributes.id = this.id;

				if (this.name)
					attributes.name = this.name;

				if (this.placeholder)
					attributes.placeholder = this.placeholder;

				if (this.disabled)
					attributes.disabled = this.disabled;

				if (this.readonly)
					attributes.readonly = this.readonly;

				if (this.required)
					attributes.required = this.required;

				if (this.class)
					attributes.class = this.class + ((this.valid) ? ' is-valid' : '') + ((this.invalid) ? ' is-invalid' : '');

				return attributes;

			},
			/*datetime() {

				let inputValue = this.$props.inputValue;

				if (inputValue == "1970-01-01 00:00:00")
					inputValue = null;

				let inputFormat = 'YYYY-MM-DD HH:mm:ss';
				if (typeof this.$props.inputFormat !== "undefined")
					inputFormat = this.$props.inputFormat;

				/!*let datetime = (inputValue && typeof (inputValue) !== "undefined") ?
					moment(inputValue).format(inputFormat) :
					((inputValue == 'now') ? moment(new Date().setHours(10,0,0,0)).format(inputFormat) : '');*!/

				let datetime = (inputValue && typeof (inputValue) !== "undefined") ?
					moment(inputValue).format('YYYY-MM-DD HH:mm:ss') :
					((inputValue == 'now') ? moment(new Date().setHours(10,0,0,0)).format('YYYY-MM-DD HH:mm:ss') : '');

				CommonService.log('debug', 'datetime', { inputValue: inputValue, datetime: datetime.toString()});

				return datetime;
			},*/
			datetime() {

				let inputValue = this.$props.inputValue;

				if (inputValue == "1970-01-01 00:00:00")
					inputValue = new Date();

				let inputFormat = 'YYYY-MM-DD HH:mm:ss';
				if (typeof this.$props.inputFormat !== "undefined")
					inputFormat = this.$props.inputFormat;

				CommonService.log('debug', 'datetime', { inputValue: inputValue, inputFormat: inputFormat });

				if (inputValue)
					return moment(inputValue).format(inputFormat);
				else
					return null;
			},
			startDate: {
				get() {

					let inputValue = this.$props.inputValue;

					if (inputValue == "1970-01-01 00:00:00")
						inputValue = null;

					let datetime = (inputValue && typeof (inputValue) !== "undefined") ?
						moment(inputValue).format('YYYY-MM-DD HH:mm:ss') :
						((inputValue == 'now') ? moment(new Date().setHours(10,0,0,0)).format('YYYY-MM-DD HH:mm:ss') : '');

					CommonService.log('debug', 'datetime', { inputValue: inputValue, datetime: datetime});


					return datetime;
				},
				set(value) {
					moment(value).format('YYYY-MM-DD HH:mm:ss');
				}
			}
		},
	}
</script>

<style lang="scss">
$vdpColor: #4Caf50;
$vdpColorWarning: #F24646;
@import '~vue-date-pick/src/vueDatePick.scss';

.vdpComponent {
	width: 100%;
	.vdpClearInput {
		display: none;
	}
	&.is-valid {
		.vdpInnerWrap {
			border-color: $vdpColor;
		}
	}
	&.is-invalid {
		.vdpInnerWrap {
			border-color: $vdpColorWarning;
		}
	}
}
.vdpOuterWrap {
	&.vdpFloating {
		z-index: 1058;
	}
	.vdpInnerWrap {
		z-index: 1060;
		.vdpHeader {
			.vdpArrow {
				&.vdpArrowPrev,
				&.vdpArrowNext {
					pointer-events: auto;
				}
			}
			.vdpPeriodControl > select{
				-webkit-appearance: menulist-button;
			}
			.vdpPeriodControl > button {
				-webkit-appearance: button;
			}
		}
	}
}

.vdpTimeControls {
	.vdpTimeCaption {

	}
	.vdpTimeUnit {
		pre {
			display: flex;
			align-items: center;
			text-align: center;
			color: #222222;
			font-family: 'Lato';
			font-style: normal;
			font-weight: 400;
			font-size: 32px;
			line-height: 38px;
		}
	}
	.vdpTimeSeparator {

	}
}

.outOfRange {
    display: none;
}

</style>